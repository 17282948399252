import { connect } from 'react-redux';
import
UpdateRealEstateObjectPage
  from 'realtor/components/pages/RealEstateObjectPages/UpdateRealEstateObjectPage/UpdateRealEstateObjectPage';
import {
  updateRealEstateObject,
  getMyRealEstateObject,
  addObjectPhotos,
  deleteObjectPhoto,
  fetchTagsList, createNewTag, dropCreatedTag,
} from 'realtor/actions';

const mapStateToProps = ({ appConfigurations, realEstateObject, tag }, { match }) => {
  return {
    appConfigurations,
    inProgress: realEstateObject.inProgress,
    realEstateObject: realEstateObject.realEstateObject,
    realEstateObjectId: Number(match.params.realEstateObjectId),
    tag,
    objectMutationInProgress: realEstateObject.objectMutationInProgress,
  };
};

const mapDispatchToProps = {
  updateRealEstateObject,
  getMyRealEstateObject,
  addObjectPhotos,
  deleteObjectPhoto,
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRealEstateObjectPage);
