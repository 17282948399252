import thunkMiddleware from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
/* @ts-ignore */
import { loggerMiddleware, devToolsEnhancer } from '../common/middleware/reduxDevTools';
/* @ts-ignore */
import catchErrors from '../common/middleware/catchErrors';
/* @ts-ignore */
import rootReducer from './reducers/index';
const middlewares = [thunkMiddleware, catchErrors, ...loggerMiddleware];
const enhancers = [...devToolsEnhancer];
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(middlewares);
    },
    enhancers: (getDefaultEnhancers) => {
        return getDefaultEnhancers().concat(enhancers);
    },
});
setupListeners(store.dispatch);
