import {
  getErrorBasedOnCurrency,
  validateMaxValue,
} from 'realtor/helpers/validationHelpers';

export class BaseValidator {
  constructor(filter, exchangeRates) {
    this.filter = filter;
    this.exchangeRates = exchangeRates;
    this.MEDIUMINT_MAX = 16777215;
  }

  validateMaxValue(value) {
    return validateMaxValue(value, this.MEDIUMINT_MAX);
  }

  validatePriceWithCurrency(price) {
    if (!price) return;

    const { filter, exchangeRates, MEDIUMINT_MAX } = this;

    return getErrorBasedOnCurrency(price, filter.currency, MEDIUMINT_MAX, exchangeRates);
  }

  validateFloor() {
    return this.validateMaxValue(
      this.filter.floor
    );
  }

  validateArea() {
    return this.validateMaxValue(
      this.filter.area
    );
  }

  validateLivingArea() {
    return this.validateMaxValue(
      this.filter.livingArea
    );
  }

  validateKitchenArea() {
    return this.validateMaxValue(
      this.filter.kitchenArea
    );
  }

  validateFlooring() {
    return this.validateMaxValue(
      this.filter.flooring
    );
  }

  validateSquarePrice() {
    return this.validatePriceWithCurrency(
      this.filter.squarePrice
    );
  }

  validateLandAreaPrice() {
    return this.validatePriceWithCurrency(
      this.filter.landAreaPrice
    );
  }

  validateTotalPrice() {
    return this.validatePriceWithCurrency(
      this.filter.totalPrice
    );
  }
}
