import { Chip } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => {
  return {
    filtersCount: {
      marginLeft: theme.spacing(1),
      padding: `0px ${theme.spacing(1)}px`,
      cursor: 'pointer',
    },
    hasNewClientParams: {
      background: 'rgba(3,236,7,0.39)',
    },
    noNewClientParams: {
      background: 'rgba(229,236,3,0.39)',
    },
  };
};

const ClipWithCounter = ({
  classes,
  count,
  onClick,
  isActive,
}) => {
  const chipClasses = cn({
    [classes.filtersCount]: true,
    [classes.hasNewClientParams]: isActive,
    [classes.noNewClientParams]: !isActive,
  });

  return (
    <Chip
      onClick={onClick}
      className={chipClasses}
      label={count}
      size="small"
    />
  );
};

ClipWithCounter.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default withStyles(styles)(ClipWithCounter);
