import { connect } from 'react-redux';
import
CreateRealEstateObjectPage
  from 'realtor/components/pages/RealEstateObjectPages/CreateRealEstateObjectPage/CreateRealEstateObjectPage';
import { createNewTag, createRealEstateObject, dropCreatedTag, fetchTagsList } from 'realtor/actions';

const mapStateToProps = ({ appConfigurations, tag, realEstateObject }) => {
  return {
    appConfigurations,
    inProgress: false,
    tag,
    objectMutationInProgress: realEstateObject.objectMutationInProgress,
  };
};

const mapDispatchToProps = {
  createRealEstateObject,
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRealEstateObjectPage);
