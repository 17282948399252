import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import i18n from '../../../../../common/i18n';
import SelectButtonByFilter from '../../common/SelectButtonByFilter/SelectButtonByFilter';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import FiltersByType from './FiltersByType';
import FiltersDrawer from 'realtor/components/common/FitlersDrawer/FiltersDrawer';
import {
  searchClientFilterFormValidationFactory,
} from 'realtor/validation/searchClientFilter/clientFilterFormValidationFactory';

const DEFAULT_FILTER = {
  currency: 0,
};

// eslint-disable-next-line max-statements
export const FiltersBar = ({
  filter,
  setFilters,
  onReset,
  filtersList,
  exchangeRates,
  paramsList,
  onConfirm,
  onFiltersToggle,
  isFiltersOpen,
  citiesList,
}) => {
  const [filterErrors, setFilterErrors] = useState({});

  const handleReset = () => {
    setFilters(DEFAULT_FILTER);
    setFilterErrors({});
    onReset();
  };

  const handleSelectButtonChange = (name, value) => {

    const valueForSet = value !== filter[name] ? value : null;

    const filterToUpdate = {
      ...filter,
      [name]: valueForSet,
    };

    if (name === 'objectServiceType' && valueForSet !== filtersList.objectServiceType.BUY) {
      filterToUpdate.isMortgage = false;
    }

    return setFilters(filterToUpdate);
  };

  const handleFilterChange = (name, value) => {
    const filterToUpdate = {
      ...filter,
      [name]: value,
    };

    return setFilters(filterToUpdate);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    return handleFilterChange(name, value);
  };

  const validateFilterForm = () => {
    const validator = searchClientFilterFormValidationFactory(
      paramsList.objectType,
      exchangeRates,
      filter
    );

    const errors = validator.validate();
    setFilterErrors(errors);

    return {
      isFilterFormValid: FormValidation.isFormValid(errors),
      realEstateObjectErrors: errors,
    };
  };

  const handleConfirm = () => {
    const { isFilterFormValid } = validateFilterForm();

    return isFilterFormValid && onConfirm(filter);
  };

  const handleTypeChange = (name, value) => {
    const objectType = value !== filter.objectType ? value : null;

    return setFilters({
      ...DEFAULT_FILTER,
      objectType,
    });
  };

  const handleCityChange = (event) => {
    const cityId = get(event, 'cityId', null);
    const districtId = cityId ? filter.districtId : null;

    setFilters({
      ...filter,
      cityId,
      districtId,
    });
  };

  const handleDistrictChange = (data) => {
    return setFilters({
      ...filter,
      districtId: data ? data.id : null,
    });
  };

  const renderFiltersListByType = () => {
    return (
      <FiltersByType
        filtersList={filtersList}
        filter={filter}
        onDistrictChange={handleDistrictChange}
        onCityChange={handleCityChange}
        onFilterChange={handleFilterChange}
        onSelectButtonChange={handleSelectButtonChange}
        onInputChange={handleInputChange}
        filterErrors={filterErrors}
        citiesList={citiesList}
      />
    );
  };

  const renderTypeFilter = () => {
    const filterName = 'objectType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_TYPE_';
    const header = `${i18n.t('PARAMS_FILTER_TYPE')}:`;

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={handleTypeChange}
      />
    );
  };

  const typeFilter = renderTypeFilter();
  const filtersListByTypeComponent = renderFiltersListByType();

  return (
    <FiltersDrawer
      isFiltersOpen={isFiltersOpen}
      onFiltersToggle={onFiltersToggle}
      typeFilterComponent={typeFilter}
      filtersListByTypeComponent={filtersListByTypeComponent}
      onConfirm={handleConfirm}
      onReset={handleReset}
      clientFilterDrawer={true}
    />
  );
};

export default FiltersBar;

FiltersBar.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  isFiltersOpen: PropTypes.bool,
  filtersList: PropTypes.object,
  exchangeRates: PropTypes.object,
  paramsList: PropTypes.object,
  citiesList: PropTypes.array,
  onConfirm: PropTypes.func,
  onReset: PropTypes.func,
};
