import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import i18n from '../../../../../../common/i18n';
import PropTypes from 'prop-types';
import ObjectListContent from 'realtor/components/common/ObjectListContent/ObjectListContent';
import { RealEstateFilterParamsBuilder } from 'realtor/helpers/requestDataBuildes/RealEstateFilterParamsBuilder';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import NoObjects from 'realtor/components/common/ObjectListContent/layouts/NoObjects/NoObjects';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';

const ORDER_LIST = {
  FROM_NEWEST_TO_OLDER: {
    order: 'created_at',
    dir: 'DESC',
  },
  FROM_OLDER_TO_NEWEST: {
    order: 'created_at',
    dir: 'ASC',
  },
  MY_FIRST: {
    order: 'my_first',
    dir: 'DESC',
  },
  FROM_CHEAP_TO_EXPENSIVE: {
    order: 'total_price',
    dir: 'ASC',
  },
  FROM_EXPENSIVE_TO_CHEAP: {
    order: 'total_price',
    dir: 'DESC',
  },
};

const ORDER_KEYS = Object.keys(ORDER_LIST);

export const SearchObjectPage = (props) => {
  const {
    fetchObjects,
    classes,
    getRealEstateObjectTotalCount,
    totalCountInProgress,
    objectsWithoutFiltersTotalCount,
    favoriteActionInProgress,
    dropRealEstateObjectsTotalCount,
    ...otherProps
  } = props;

  const pageTitle = i18n.t('SEARCH_OBJECT_PAGE_TITLE');

  useEffect(() => () => {
    dropRealEstateObjectsTotalCount();
  }, []);

  useEffect(() => {
    setPageTitle(pageTitle);
    getRealEstateObjectTotalCount();
  },[]);

  const handleFetchObjects = (params) => {
    const requestParams = RealEstateFilterParamsBuilder.build(params);
    const { paramsList } = otherProps;
    // check if Selected all possible options
    // We exclude this filter values because Multi value filter we set to all by default
    // and if the prop is missing in the object we won't be able to filter it so we avoid sending whole
    // arrays of possible options,
    // to delete this kind of workaround we need to get rid of sets which store this values...
    const roomsCount = requestParams.roomsCount && requestParams.roomsCount.length ===
    Object.entries(paramsList.roomsCount).length ?
      [] : requestParams.roomsCount;
    const objectMaterial = requestParams.objectMaterial && requestParams.objectMaterial.length
    === Object.entries(paramsList.objectMaterial).length ?
      [] : requestParams.objectMaterial;
    const objectCondition = requestParams.objectCondition && requestParams.objectCondition.length
    === Object.entries(paramsList.objectCondition).length ?
      [] : requestParams.objectCondition;

    const updatedParams = {
      ...requestParams,
      roomsCount,
      objectMaterial,
      objectCondition,
    };

    fetchObjects(updatedParams);
  };

  const isNoContentVisible = !totalCountInProgress && objectsWithoutFiltersTotalCount === 0;

  if (isNoContentVisible) {
    return (<NoObjects classes={classes} />);
  }

  const isLoaderVisible = totalCountInProgress || objectsWithoutFiltersTotalCount === null;

  const listContent = (
    <div>
      {favoriteActionInProgress && <FullScreenLoader />}
      <ObjectListContent
        classes={classes}
        {...otherProps}
        fetchTotalAndList={fetchObjects}
        fetchObjects={handleFetchObjects}
        pageTitle={pageTitle}
        isCreateButtonDisabled={true}
        orderList={ORDER_LIST}
        orderKeys={ORDER_KEYS}
        objectsWithoutFiltersTotalCount={objectsWithoutFiltersTotalCount}
      />
    </div>
  );

  return isLoaderVisible ? <CircularProgressBox /> : listContent;
};

SearchObjectPage.propTypes = {
  classes: PropTypes.object,
  isOwnerList: PropTypes.bool,
  objectList: PropTypes.array,
  citiesList: PropTypes.array,
  fetchObjects: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  favoriteActionInProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  paramsList: PropTypes.object,
  addRealEstateToFavorite: PropTypes.func,
  removeRealEstateFromFavorite: PropTypes.func,
  getRealEstateObjectTotalCount: PropTypes.func,
  dropRealEstateObjectsTotalCount: PropTypes.func,
  totalCountInProgress: PropTypes.bool.isRequired,
  objectsWithoutFiltersTotalCount: PropTypes.number,
  exchangeRates: PropTypes.array,
};

export default withStyles(styles)(SearchObjectPage);
