import RealtorResource from '../resourses/Realtor.resource';
import { ConfirmProfileRequestDateBuilder } from '../helpers/requestDataBuildes';
import i18n from '../../../common/i18n';
import Alert from 'react-s-alert';
import { onRedirect } from '../../actions/redirect';
import { TelephoneNumberBuilder, UserProfile } from 'realtor/domain';
import { UpdateProfileRequestDateBuilder } from 'realtor/helpers/requestDataBuildes/UpdateProfileRequestDateBuilder';

export const CONFIRM_PROFILE_START = 'CONFIRM_PROFILE_START';
export const CONFIRM_PROFILE_FAIL = 'CONFIRM_PROFILE_FAIL';
export const CONFIRM_PROFILE_SUCCESS = 'CONFIRM_PROFILE_SUCCESS';
export const DROP_CONFIRM_PROFILE_ERRORS = 'DROP_CONFIRM_PROFILE_ERRORS';
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';

const updateProfileStart = () => {
  return {
    type: UPDATE_PROFILE_START,
  };
};

const confirmProfileStart = () => {
  return { type: CONFIRM_PROFILE_START };
};

const confirmProfileSuccess = (profile) => {
  const profileEntity = new UserProfile({
    ...profile,
    telephoneNumber: TelephoneNumberBuilder.buildFromFormData(profile.telephoneNumber),
    moreTelephoneNumbers: profile.moreTelephoneNumbers ?
      profile.moreTelephoneNumbers.map(
        (telephoneNumber) => TelephoneNumberBuilder.buildFromFormData(
          telephoneNumber.telephoneNumber,
          telephoneNumber.id
        )
      ) :
      [],
  });
  return { type: CONFIRM_PROFILE_SUCCESS, profile: profileEntity };
};

const confirmProfileFail = (error) => {
  return { type: CONFIRM_PROFILE_FAIL, error };
};

const dropErrors = () => {
  return { type: DROP_CONFIRM_PROFILE_ERRORS };
};

export const dropRealtorProfileErrors = () => {
  return (dispatch) => {
    return dispatch(dropErrors());
  };
};

export const confirmRealtorProfile = (profileData) => {
  return (dispatch) => {

    dispatch(confirmProfileStart());

    const requestData = ConfirmProfileRequestDateBuilder.build(profileData);

    RealtorResource.confirmProfile(requestData)
      .then((response) => {
        dispatch(confirmProfileSuccess(response));
        return dispatch(onRedirect('/clients'));
      })
      .catch(({ error }) => {
        return dispatch(confirmProfileFail(error));
      });
  };
};

export const updateClientProfile = (profileData) => {
  return (dispatch) => {
    const requestData = UpdateProfileRequestDateBuilder.build(profileData);

    dispatch(updateProfileStart());

    RealtorResource.partialUpdateProfile(requestData)
      .then((response) => {
        dispatch(confirmProfileSuccess(response));
        Alert.success(
          i18n.t('PROFILE_SUCCESSFULLY_UPDATED'),
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
        return dispatch(onRedirect('/profile'));
      });
  };
};
