import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  RoomsCountFilter,
  CurrencyFilter,
  CitiesFilter,
  PartialServiceTypeFilter,
  ServiceTypeFilter,
  MaterialFilter,
  ConditionFilter,
  MortgageFilter,
  LastFloorFilter,
  SubtypeFilter,
  IgnoreLastFloorFilter,
} from '../Filters';
import {
  LandAreaPriceRangeFilter,
  FlooringRangeFilter,
  AreaRangeFilter,
  SquarePriceRangeFilter,
  KitchenAreaRangeFilter,
  TotalPriceRangeFilter,
  FloorRangeFilter,
  LivingAreaRangeFilter,
  LandAreaRangeFilter,
} from 'realtor/components/layears/RangeFilters';

// eslint-disable-next-line max-statements
export const ObjectFiltersByType = ({
  filtersList,
  filter,
  onSelectButtonChange,
  onSelectButtonMultipleChange,
  onMultiSelectAll,
  onFilterChange,
  filterErrors,
  citiesList,
  onCityChange,
  onDistrictChange,
}) => {
  const renderServiceTypeFilter = () => {
    return <ServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange}/>;
  };

  const renderSubtypeFilter = () => {
    return (
      <SubtypeFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}
      />
    );
  };

  const renderMortgageFilter = () => {
    return <MortgageFilter filter={filter} onFilterChange={onFilterChange}/>;
  };

  const renderLastFloorFilter = () => {
    return <LastFloorFilter filter={filter} onFilterChange={onFilterChange}/>;
  };

  const renderPartialServiceTypeFilter = () => {
    return <PartialServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange}/>;
  };

  const renderRoomsCount = () => {
    return (
      <RoomsCountFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}/>
    );
  };

  const renderMaterial = () => {
    return (
      <MaterialFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}
      />
    );
  };

  const renderCondition = () => {
    return (
      <ConditionFilter
        filtersList={filtersList}
        filter={filter}
        onFilterChange={onSelectButtonMultipleChange}
        onMultiSelectAll={onMultiSelectAll}
        isMulti={true}
      />
    );
  };

  const renderIgnoreLastFloorFilter = () => <IgnoreLastFloorFilter filter={filter} onFilterChange={onFilterChange} />;

  const renderCurrency = () => {
    return <CurrencyFilter filtersList={filtersList} filter={filter} onFilterChange={onFilterChange}/>;
  };

  const renderTotalPriceRange = () => {
    return (
      <TotalPriceRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  };

  const renderSquarePriceRange = () => {
    return (
      <SquarePriceRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  };

  const renderLandAreaRangeFilter = () => {
    return (<LandAreaRangeFilter
      onSelect={onMultiSelectAll}
      filter={filter}
      errors={filterErrors}
    />);
  };

  const landAreaPriceRange = () => {
    return (
      <LandAreaPriceRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  };

  const renderArea = () => {
    return (
      <AreaRangeFilter
        filter={filter}
        onSelect={onMultiSelectAll}
        errors={filterErrors}
      />
    );
  };

  const renderFlooringRange = () => {
    return (
      <FlooringRangeFilter
        filter={filter}
        onSelect={onMultiSelectAll}
        errors={filterErrors}
      />
    );
  };

  const renderFloorRange = () => {
    return (
      <FloorRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  };

  const renderKitchenAreaRange = () => {
    return (
      <KitchenAreaRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  };

  const renderLivingAreaRange = () => {
    return (
      <LivingAreaRangeFilter
        onSelect={onMultiSelectAll}
        filter={filter}
        errors={filterErrors}
      />
    );
  };

  const renderCitiesFilter = () => {
    return (<CitiesFilter
      citiesList={citiesList}
      filter={filter}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
    />);
  };

  switch (filter.objectType) {
  case filtersList.objectType.APARTMENT:
    return (
      <Fragment>
        {renderServiceTypeFilter()}
        {renderSubtypeFilter()}
        {renderRoomsCount()}
        {renderCurrency()}
        {renderTotalPriceRange()}
        {renderSquarePriceRange()}
        {renderArea()}
        {renderMortgageFilter()}
        {renderFloorRange()}
        {renderIgnoreLastFloorFilter()}
        {renderLastFloorFilter()}
        {renderKitchenAreaRange()}
        {renderLivingAreaRange()}
        {renderCondition()}
        {renderMaterial()}
        {renderCitiesFilter()}
      </Fragment>
    );
  case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
    return (
      <Fragment>
        {renderServiceTypeFilter()}
        {renderSubtypeFilter()}
        {renderRoomsCount()}
        {renderCurrency()}
        {renderTotalPriceRange()}
        {renderSquarePriceRange()}
        {renderArea()}
        {renderLandAreaRangeFilter()}
        {renderMortgageFilter()}
        {renderFlooringRange()}
        {renderCondition()}
        {renderMaterial()}
        {renderCitiesFilter()}
      </Fragment>
    );
  case filtersList.objectType.COMMERCIAL:
    return (
      <Fragment>
        {renderPartialServiceTypeFilter()}
        {renderSubtypeFilter()}
        {renderCurrency()}
        {renderTotalPriceRange()}
        {renderSquarePriceRange()}
        {renderArea()}
        {renderFloorRange()}
        {renderIgnoreLastFloorFilter()}
        {renderLastFloorFilter()}
        {renderCondition()}
        {renderMaterial()}
        {renderCitiesFilter()}
      </Fragment>
    );
  case filtersList.objectType.LAND_PLOT:
    return (
      <Fragment>
        {renderPartialServiceTypeFilter()}
        {renderCurrency()}
        {renderTotalPriceRange()}
        {renderArea()}
        {landAreaPriceRange()}
        {renderCitiesFilter()}
      </Fragment>
    );
  case filtersList.objectType.GARAGE_PARKING:
    return (
      <Fragment>
        {renderPartialServiceTypeFilter()}
        {renderSubtypeFilter()}
        {renderCurrency()}
        {renderTotalPriceRange()}
        {renderArea()}
        {renderCitiesFilter()}
      </Fragment>
    );
  default:
    return (
      <Fragment>
      </Fragment>
    );
  }
};

export default ObjectFiltersByType;

ObjectFiltersByType.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterErrors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onSelectButtonChange: PropTypes.func.isRequired,
  onSelectButtonMultipleChange: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
};
