import {
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL,
  FETCH_CLIENTS_START,
  FETCH_TOTAL_START,
  FETCH_TOTAL_SUCCESS,
  FETCH_TOTAL_FAIL,
  SET_CLIENTS_LIST,
  CLIENT_FAVORITE_ACTION_START,
  CLIENT_FAVORITE_ACTION_FAILED,
} from '../actions';

const initialState = {
  listInProgress: false,
  favoriteActionInProgress: false,
  inProgress: false,
  error: null,
  list: [],
  totalListCount: 0,
  totalCount: null,
};

export const clientList = (state = initialState, action) => {
  switch (action.type) {
  case CLIENT_FAVORITE_ACTION_START:
    return {
      ...state,
      error: null,
      favoriteActionInProgress: true,
    };
  case CLIENT_FAVORITE_ACTION_FAILED:
    return {
      ...state,
      error: action.error,
      favoriteActionInProgress: false,
    };
  case FETCH_CLIENTS_START:
    return {
      ...state,
      error: null,
      listInProgress: true,
    };
  case FETCH_TOTAL_START:
    return {
      ...state,
      error: null,
      inProgress: true,
    };

  case FETCH_CLIENTS_FAIL:
    return {
      ...state,
      error: action.error,
      listInProgress: false,
    };
  case FETCH_TOTAL_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };

  case FETCH_CLIENTS_SUCCESS:
    return {
      ...state,
      error: null,
      list: action.data.list,
      totalListCount: action.data.total,
      listInProgress: false,
    };
  case FETCH_TOTAL_SUCCESS:
    return {
      ...state,
      error: null,
      totalCount: action.data.total,
      inProgress: false,
    };
  case SET_CLIENTS_LIST:
    return {
      ...state,
      list: action.clientsList,
      favoriteActionInProgress: false,
    };
  default:
    return state;
  }
};
