/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

import ClientsList from '../../layears/ClientsList/ClientsList';
import Pagination from '../../layears/Pagination/Pagination';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import ClientsControlPanel from '../../layears/ClientsControlPanel/ClientsControlPanel';
import {
  DeleteClientDialog,
  DeleteClientFilterDialog,
  DeleteClientFromFavoriteDialog,
} from 'realtor/components/dialogs';
import FiltersBar from '../../layears/FiltersBar/FiltersBar';
import { get, isEmpty } from 'lodash';
import NoClients from 'realtor/components/pages/ClientsPages/ClientsListPage/layouts/NoClients/NoClients';
import NoClientsFound
  from 'realtor/components/pages/ClientsPages/ClientsListPage/layouts/NoClientsFound/NoClientsFound';
import PageHeader from 'realtor/components/pages/ClientsPages/ClientsListPage/layouts/PageHeader/PageHeader';
import i18n from '../../../../../common/i18n';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import { getFilterParamsFromQuery } from 'realtor/components/layears/ClipObjectFiltersCount/helpers/searchUrl';
import { useLocation, useHistory } from 'react-router-dom';

const DEFAULT_LIMITS = [10, 30];
const DEFAULT_OFFSET = 0;

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMITS[0],
  offset: DEFAULT_OFFSET,
};

const DEFAULT_FILTERS = {
  search: '',
  showClientsWithoutParams: false,
  showOnlyFavorites: false,
};

// eslint-disable-next-line max-statements
const ClientList = (
  {
    classes,
    totalCount,
    clientList,
    citiesList,
    isOwnerList,
    pageTitle,
    fetchClients,
    orderKeys,
    orderList,
    paramsList,
    tagsList,
    fetchTagsList,
    onDelete,
    onDeleteClientFilter,
    onAddToFavorite,
    onRemoveFromFavorite,
    inProgress,
    isCreateButtonDisabled,
    listInProgress,
    totalListCount,
    exchangeRates,
  }
) => {

  const history = useHistory();
  const { search } = useLocation();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filterBarState, setFilterBarState] = useState(DEFAULT_FILTERS);
  const [listFetchParams, setListFetchParams] = useState({
    pagination: DEFAULT_PAGINATION,
    filters: DEFAULT_FILTERS,
    paramsFilter: {},
    order: 'FROM_NEWEST_TO_OLDER',
  });

  useEffect(() => {
    if (!search) {
      fetchClients(getFetchParams(listFetchParams));
    }
  }, [listFetchParams]);

  useEffect(() => {
    if (!isOwnerList && search) {
      const params = getFilterParamsFromQuery(search);

      setListFetchParams({
        ...listFetchParams,
        paramsFilter: params,
      });
      setFilterBarState(params);

      handleFilterChange(params);

      if (search) {
        history.replace({
          pathname: window.location.pathname,
          state: history.location.state,
        });
      }
    }

  }, [search]);

  const [clientDeleteDialog, setClientDeleteDialog] = useState({
    clientToDelete: null,
    isDeleteDialogOpen: false,
  });

  const [clientToDeleteFromFavorite, setClientToDeleteFromFavorite] = useState({
    clientToDeleteFromFavorite: null,
    isDeleteFromFavoriteDialogOpen: false,
  });

  const [clientFilterDeleteDialog, setClientFilterDeleteDialog] = useState({
    filterId: null,
    clientId: null,
    isFilterDeleteDialogOpen: false,
  });

  const getFetchParams = (params) => {
    const tagId = params.filters.tag ? params.filters.tag.id : undefined;
    const { tag, ...filters } = params.filters;

    return {
      ...params.pagination,
      ...filters,
      ...params.paramsFilter,
      ...orderList[params.order],
      tagId,
    };
  };

  const handleFiltersToggle = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const handleLimitChange = (limit) => {
    const pagination = {
      limit,
      offset: 0,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination,
    });
  };

  const handlePaginationBack = () => {
    const { pagination: { offset, limit } } = listFetchParams;

    const pagination = {
      ...listFetchParams.pagination,
      offset: offset - limit,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination,
    }
    );
  };

  const handlePaginationForward = () => {
    const { pagination: { offset, limit } } = listFetchParams;

    const pagination = {
      ...listFetchParams.pagination,
      offset: offset + limit,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination,
    });
  };

  const handleSearchChange = (params) => {
    setListFetchParams({
      ...listFetchParams,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
      filters: {
        ...listFetchParams.filters,
        search: params.search,
        tag: get(params, 'tag', null),
      },
    });
  };

  const handleDeleteClient = (client) => {
    setClientDeleteDialog({
      clientToDelete: client,
      isDeleteDialogOpen: true,
    });
  };

  const handleDeleteClientFilter = (clientId, filterId) => {
    setClientFilterDeleteDialog({
      filterId,
      clientId,
      isFilterDeleteDialogOpen: true,
    });
  };

  const handleDeleteClientFromFavoriteFilter = (client) => {
    setClientToDeleteFromFavorite({
      isDeleteFromFavoriteDialogOpen: true,
      clientToDeleteFromFavorite: client,
    });
  };

  const handleDeleteClientFromFavoriteCancel = () => {
    setClientToDeleteFromFavorite({
      isDeleteFromFavoriteDialogOpen: false,
      clientToDeleteFromFavorite: null,
    });
  };

  const handleDeleteClientFromFavoriteConfirm = () => {
    onRemoveFromFavorite(clientToDeleteFromFavorite.clientToDeleteFromFavorite);

    setClientToDeleteFromFavorite({
      isDeleteFromFavoriteDialogOpen: false,
      clientToDeleteFromFavorite: null,
    });
  };

  const handleDeleteClientConfirm = () => {
    onDelete(
      clientDeleteDialog.clientToDelete,
      getFetchParams(listFetchParams)
    );

    setClientDeleteDialog({
      clientToDelete: null,
      isDeleteDialogOpen: false,
    });
  };

  const handleDeleteClientFilterConfirm = () => {
    const newState = {
      filterId: null,
      clientId: null,
      isFilterDeleteDialogOpen: false,
    };

    onDeleteClientFilter(
      clientFilterDeleteDialog.clientId,
      clientFilterDeleteDialog.filterId,
      getFetchParams(listFetchParams)
    );

    setClientFilterDeleteDialog(newState);
  };

  const handleDeleteClientCancel = () => {
    setClientDeleteDialog({
      clientToDelete: null,
      isDeleteDialogOpen: true,
    });
  };

  const handleDeleteClientFilterCancel = () => {
    setClientFilterDeleteDialog({
      filterId: null,
      clientId: null,
      isFilterDeleteDialogOpen: false,
    });
  };

  const handleFilterChange = (filters) => {
    setIsFiltersOpen(false);

    setListFetchParams({
      ...listFetchParams,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
      paramsFilter: filters,
    });
  };
  const handleShowClientsWithoutParamsChange = () => {
    const showClientsWithoutParams = !listFetchParams.filters.showClientsWithoutParams;

    const filters = {
      ...listFetchParams.filters,
      showClientsWithoutParams,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
      filters,
    });
  };

  const handleShowOnlyFavoritesChange = () => {
    const showOnlyFavorites = !listFetchParams.filters.showOnlyFavorites;

    const filters = {
      ...listFetchParams.filters,
      showOnlyFavorites,
    };

    setListFetchParams({
      ...listFetchParams,
      filters,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
    });

    setIsFiltersOpen(false);
  };

  const handleFilterReset = () => {
    setListFetchParams({
      ...listFetchParams,
      paramsFilter: {},
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
    });

    setIsFiltersOpen(false);
  };

  const handleOrderChange = (order) => {
    setListFetchParams({
      ...listFetchParams,
      order,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
    });
  };

  const renderHeader = () => {
    return (<PageHeader
      classes={classes}
      totalCount={totalCount}
      title={pageTitle}
      isCreateButtonDisabled={isCreateButtonDisabled}
    />);
  };

  const renderEmptyClients = () => {
    return <NoClients classes={classes}/>;
  };

  const renderNoClientsFound = () => {
    return <NoClientsFound classes={classes}/>;
  };

  const renderClientsContent = () => {
    if (listInProgress) {
      return renderProgress();
    }

    const content = clientList.length ? renderClientsList() : renderNoClientsFound();

    return (
      <Fragment>
        {renderTopControls()}
        {content}
      </Fragment>
    );
  };

  const renderTopControls = () => {
    const isClientsListNotEmpty = Boolean(clientList.length);
    return (
      <Grid className={classes.controlPanelBox} container>
        <Grid className={classes.controlPanelBox} style={{ padding: '10px 0px' }} item container md={6} xs={12}>
          {isOwnerList ? renderWithParamsSwitcher() : renderOnlyFavoriteSwitcher()}
        </Grid>
        <Grid item md={6} xs={12}>
          {isClientsListNotEmpty && renderPagination()}
        </Grid>
      </Grid>
    );
  };

  const renderClientsList = () => {
    return (
      <Fragment>
        <ClientsList
          onDeleteFilter={handleDeleteClientFilter}
          clients={clientList}
          onDelete={handleDeleteClient}
          paramsList={paramsList}
          onSelectTag={handleSearchChange}
          isOwnerList={isOwnerList}
          onRemoveFromFavorite={handleDeleteClientFromFavoriteFilter}
          onAddToFavorite={onAddToFavorite}
        />
        {renderPagination()}
      </Fragment>
    );
  };

  const renderWithParamsSwitcher = () => {
    return (<CustomSwitch
      checked={listFetchParams.filters.showClientsWithoutParams}
      label={i18n.t('CLIENTS_WITHOUT_PARAMS')}
      onChange={handleShowClientsWithoutParamsChange}
      name={'showClientsWithoutParams'}
      asButton={true}
    />);
  };

  const renderOnlyFavoriteSwitcher = () => {
    return (<CustomSwitch
      checked={listFetchParams.filters.showOnlyFavorites}
      label={i18n.t('ONLY_FAVORITES')}
      onChange={handleShowOnlyFavoritesChange}
      name={'showOnlyFavorites'}
      asButton={true}
    />);
  };

  const renderPagination = () => {
    return (
      <Pagination
        limitsList={DEFAULT_LIMITS}
        onLimitChange={handleLimitChange}
        limit={listFetchParams.pagination.limit}
        offset={listFetchParams.pagination.offset}
        total={totalListCount}
        onPaginationBack={handlePaginationBack}
        onPaginationForward={handlePaginationForward}
      />
    );
  };

  const renderProgress = () => {
    return <CircularProgressBox/>;
  };

  const contentStyle = isFiltersOpen ? classes.contentBoxWithOpenedFilter : classes.contentBox;
  const isDataLoadInProgress = inProgress || totalCount === null;
  const { paramsFilter, order, filters: { showClientsWithoutParams } } = listFetchParams;

  const renderContent = () => {
    return (
      <Fragment>
        <div className={contentStyle}>
          {renderHeader()}
          <Paper className={classes.paper} elevation={0}>
            <ClientsControlPanel
              onSearchChange={handleSearchChange}
              onFiltersToggle={handleFiltersToggle}
              isFiltersButtonActive={!isEmpty(paramsFilter)}
              orderList={orderKeys}
              currentOrder={order}
              onOrderChange={handleOrderChange}
              fetchTagsList={fetchTagsList}
              tagsList={tagsList}
              isFiltersButtonDisabled={showClientsWithoutParams}
              selectedTag={listFetchParams.filters.tag}
            />
            {totalCount ? renderClientsContent() : renderEmptyClients()}
          </Paper>
        </div>
        <DeleteClientDialog
          client={clientDeleteDialog.clientToDelete}
          isDeleteDialogOpen={clientDeleteDialog.isDeleteDialogOpen}
          onDeleteConfirm={handleDeleteClientConfirm}
          onDialogClose={handleDeleteClientCancel}
        />
        <DeleteClientFromFavoriteDialog
          client={clientToDeleteFromFavorite.clientToDeleteFromFavorite}
          isDeleteDialogOpen={clientToDeleteFromFavorite.clientToDeleteFromFavorite}
          onDeleteConfirm={handleDeleteClientFromFavoriteConfirm}
          onDialogClose={handleDeleteClientFromFavoriteCancel}
        />
        <DeleteClientFilterDialog
          client={clientDeleteDialog.clientToDelete}
          isDeleteDialogOpen={clientFilterDeleteDialog.isFilterDeleteDialogOpen}
          onDeleteConfirm={handleDeleteClientFilterConfirm}
          onDialogClose={handleDeleteClientFilterCancel}
        />
        <FiltersBar
          filter={filterBarState}
          setFilters={setFilterBarState}
          onFiltersToggle={handleFiltersToggle}
          isFiltersOpen={isFiltersOpen}
          filtersList={paramsList}
          paramsList={paramsList}
          onConfirm={handleFilterChange}
          onReset={handleFilterReset}
          citiesList={citiesList}
          exchangeRates={exchangeRates}
        />
      </Fragment>
    );
  };

  return (isDataLoadInProgress ? renderProgress() : renderContent()

  );
};

ClientList.propTypes = {
  classes: PropTypes.object,
  clientList: PropTypes.array,
  citiesList: PropTypes.array,
  tagsList: PropTypes.array,
  fetchClients: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteClientFilter: PropTypes.func,
  paramsList: PropTypes.object,
  fetchTagsList: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  isCreateButtonDisabled: PropTypes.bool,
  isOwnerList: PropTypes.bool.isRequired,
  onAddToFavorite: PropTypes.func,
  onRemoveFromFavorite: PropTypes.func,
  orderKeys: PropTypes.array.isRequired,
  orderList: PropTypes.object.isRequired,
  exchangeRates: PropTypes.array,
};

export default withStyles(styles)(ClientList);
