/* eslint-disable import/max-dependencies */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MetaTags from 'react-meta-tags';
import 'react-s-alert/dist/s-alert-default.css';
import style from './styles';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import ClientContacts from 'realtor/components/layears/ObjectCard/components/ClientContacts/ClientContacts';
import { withRouter } from 'react-router-dom';
import
ControlButtons
  from 'realtor/components/layears/RealEstateObjectContent/components/ControlButtons/ControlButtons';
import { DefaultPhoto } from 'realtor/components/layears/RealEstateObjectContent/components/DefaultPhoto/DefaultPhoto';
import {
  RealEstateObjectDetailsHeader,
  RealEstateObjectDetailsLocation,
  RealEstateObjectDetailsTags,
  RealEstateObjectDetailsNotes,
  RealEstateObjectDetailsParamsList,
  RealEstateObjectDetailsPriceAndActions,
  RealEstateObjectDetailsDescription,
  RealEstateObjectDetailsImages,
  RealEstateObjectDetailsCreatedAt,
} from './components';
import DeleteRealEstateFromFavoriteDialog
  from 'realtor/components/dialogs/DeleteRealEstateFromFavoriteDialog/DeleteRealEstateFromFavoriteDialog';

// TODO: Separate this component by blocks
// eslint-disable-next-line max-statements
export const RealEstateObjectComponent = ({
  realEstateObject,
  filters,
  history,
  onShareObjectsDialogOpen,
  classes,
  hideControlButtons,
  onAddRealEstateToFavorite,
  onRemoveRealEstateFromFavorite,
}) => {
  const [realEstateObjectDeleteFromFavourite, setRealEstateObjectDeleteFromFavourite] = useState(null);

  const handleDeleteRealEstateFromFavoriteCancel = () => {
    setRealEstateObjectDeleteFromFavourite(null);
  };

  const handleDeleteRealEstateFromFavoriteConfirm = () => {
    onRemoveRealEstateFromFavorite(realEstateObjectDeleteFromFavourite);
    setRealEstateObjectDeleteFromFavourite(null);
  };

  const handleSetRealEstateObjectDeleteFromFavourite = (realEstateObject) => {
    setRealEstateObjectDeleteFromFavourite(realEstateObject);
  };
  const handleEditRealEstate = () => {
    const path = `/real-estate-object/${realEstateObject.id}/edit`;

    history.push(path);
  };

  const handleGoBack = () => {
    history.push('/objects');
  };

  const renderObjectParamsList = () => {
    return (<RealEstateObjectDetailsParamsList realEstateObject={realEstateObject} filters={filters} />);
  };

  const renderHeader = () => {
    return <RealEstateObjectDetailsHeader realEstateObject={realEstateObject} filters={filters} />;
  };

  const renderLocation = () => {
    return (<RealEstateObjectDetailsLocation realEstateObject={realEstateObject} />);
  };

  const renderContacts = () => {
    const { client } = realEstateObject;
    return (
      <div className={classes.objectContact}>
        <ClientContacts client={client}/>
      </div>
    );
  };

  const renderPriceBlock = () => {
    return (
      <Fragment>
        <RealEstateObjectDetailsPriceAndActions
          onShareObjectsDialogOpen={onShareObjectsDialogOpen}
          realEstateObject={realEstateObject}
          hideControlButtons={hideControlButtons}
          onAddRealEstateToFavorite={onAddRealEstateToFavorite}
          onRemoveRealEstateFromFavorite={handleSetRealEstateObjectDeleteFromFavourite}
        />
        <Divider/>
      </Fragment>
    );
  };

  const renderDefaultPhoto = () => {
    return (<DefaultPhoto classes={classes} onEditRealEstate={handleEditRealEstate} />);
  };

  const renderImages = () => {
    return (<RealEstateObjectDetailsImages realEstateObject={realEstateObject}/>);
  };

  const renderImageBlock = () => {
    return (
      <div className={classes.sliderBox}>
        {realEstateObject.photos.length ? renderImages() : renderDefaultPhoto()}
      </div>
    );
  };

  const renderVirtualTour = () => {
    const { virtualTourUrl } = realEstateObject;
    return (
      virtualTourUrl &&
      <div className={classes.iframe}>
        <iframe width={'100%'} height={500} src={virtualTourUrl}></iframe>
      </div>
    );
  };

  const renderDescription = () => {
    const { description } = realEstateObject;
    return (
      description &&
      <RealEstateObjectDetailsDescription description={description} />
    );
  };

  const renderCreatedAt = () => {
    const { createdAt } = realEstateObject;
    return (
      createdAt &&
      <RealEstateObjectDetailsCreatedAt createdAt={createdAt} />
    );
  };

  const renderNotes = () => {
    const { notes } = realEstateObject;
    return (notes && <RealEstateObjectDetailsNotes notes={notes} />);
  };

  const renderTags = () => {
    const { tags } = realEstateObject;

    return (
      !!tags.length &&
      <Fragment>
        <Divider/>
        <RealEstateObjectDetailsTags tags={tags} />
      </Fragment>
    );
  };

  const renderActions = () => {
    return (<ControlButtons
      onGoBack={handleGoBack}
      onShareObjectsDialogOpen={onShareObjectsDialogOpen}
      onEditRealEstate={handleEditRealEstate}
      isMyObject={realEstateObject.isMyObject}
    />);
  };

  const renderObject = () => {
    const photo = realEstateObject.photos[0] ? realEstateObject.photos[0].image.small : null;
    return (
      <Fragment>
        <MetaTags>
          <title>{realEstateObject.title}</title>
          <meta id="meta-description" name="description" content={realEstateObject.description} />
          <meta id="og-title" property="og:title" content="m2c.com.ua" />
          {photo && <meta id="og-image" property="og:image" content={photo} />}
        </MetaTags>
        <Grid container className={classes.contentBox}>
          <Grid className={classes.contentBoxItem} item sm={6} md={6} lg={6} xl={6} xs={12}>
            {renderImageBlock()}
            {renderVirtualTour()}
          </Grid>
          <Grid className={classes.contentBoxItem} item sm={6} md={6} lg={6} xl={6} xs={12}>
            {renderHeader()}
            {renderPriceBlock()}
            {renderLocation()}
            {renderContacts()}
            {renderCreatedAt()}
            {renderDescription()}
            <Grid container className={classes.paramsContainer}>
              {renderObjectParamsList()}
            </Grid>

            {renderNotes()}
            {renderTags()}
            {!hideControlButtons && renderActions()}
          </Grid>
        </Grid>
        <DeleteRealEstateFromFavoriteDialog
          isDeleteDialogOpen={!!realEstateObjectDeleteFromFavourite}
          realEstateObject={realEstateObjectDeleteFromFavourite}
          onDialogClose={handleDeleteRealEstateFromFavoriteCancel}
          onDeleteConfirm={handleDeleteRealEstateFromFavoriteConfirm}
        />
      </Fragment>
    );
  };

  return (realEstateObject && renderObject());
};

const ObjectComponentWithRouter = withRouter(RealEstateObjectComponent);

const RealEstateObjectContent = withStyles(style)(ObjectComponentWithRouter);

export default RealEstateObjectContent;

RealEstateObjectComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  realEstateObject: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  onShareObjectsDialogOpen: PropTypes.func.isRequired,
  onAddRealEstateToFavorite: PropTypes.func.isRequired,
  onRemoveRealEstateFromFavorite: PropTypes.func.isRequired,
  hideControlButtons: PropTypes.bool,
};
