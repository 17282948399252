/* eslint-disable import/max-dependencies */
import React, { PureComponent } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import Alert from '@material-ui/lab/Alert';
import RegistrationForm from '../../forms/RegistrationForm/RegistrationForm';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AuthPageLayer from '../../layears/AuthPageLayer/AuthPageLayer';
import FormHeaderLayer from '../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import AuthFooterLayer from '../../layears/AuthFooterLayer/AuthFooterLayer';
import { mapRegistrationErrors } from '../../../helpers/errorsMapper';
import i18n from '../../../../../common/i18n';

export class RegistrationPage extends PureComponent {

  state = {
    errors: {},
  }

  static getDerivedStateFromProps(props, state) {
    const { errors, errorCodes } = props;
    const errorCode = get(errors, 'code');

    const validationErrors = errorCode ? mapRegistrationErrors(errorCodes, errors) : {};

    return {
      ...state,
      errors: validationErrors,
    };
  }

  renderRegistrationSuccess = () => {
    return (
      <Alert severity="success" variant="outlined">{i18n.t('SUCCESS_REGISTRATION_TEXT')}</Alert>
    );
  }

  renderHeader = () => {
    return (
      <FormHeaderLayer title={i18n.t('REGISTRATION')}>
        {`${i18n.t('ALREADY_HAVE_ACCOUNT')} `}
        <Link to="/auth" component={RouterLink}>
          {i18n.t('SIGN_IN')}
        </Link>
      </FormHeaderLayer>
    );
  }

  render() {
    const {
      registrationSuccess,
      classes,
      registration,
      dropRegistrationErrors,
      inProgress,
    } = this.props;
    const { errors } = this.state;

    return (
      <AuthPageLayer>
        {this.renderHeader()}
        <hr size={1} className={classes.hr}/>
        {registrationSuccess ?
          this.renderRegistrationSuccess() :
          <RegistrationForm
            odRegistration={registration}
            errors={errors}
            onDropErrors={dropRegistrationErrors}
            inProgress={inProgress}
          />
        }
        <AuthFooterLayer confirmText={i18n.t('REGISTRATION_CONFIRM_TEXT')}/>
      </AuthPageLayer>
    );
  }
}

export default withStyles(styles)(RegistrationPage);

RegistrationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  registration: PropTypes.func.isRequired,
  errors: PropTypes.object,
  errorCodes: PropTypes.object,
  registrationSuccess: PropTypes.bool,
  inProgress: PropTypes.bool,
  dropRegistrationErrors: PropTypes.func.isRequired,
};
