import qs from 'qs';

export const buildClientSearchUrlParams = (clientFilter) => {
  const filterAsQueryParams = {
    roomsCount: clientFilter.roomsCount ? Array.from(clientFilter.roomsCount) : null,
    objectServiceType: clientFilter.objectServiceType,
    objectCondition: clientFilter.objectCondition ? Array.from(clientFilter.objectCondition) : null,
    objectSubtype: clientFilter.objectSubtype ? Array.from(clientFilter.objectSubtype) : null,
    objectMaterial: clientFilter.objectMaterial ? Array.from(clientFilter.objectMaterial) : null,
    objectType: clientFilter.objectType,
    cityId: clientFilter.location.props.city.props.id,
    districtId: clientFilter.location.props.district ? clientFilter.location.props.district.props.id : null ,
    totalPriceFrom: clientFilter.totalPriceFrom,
    totalPriceTo: clientFilter.totalPriceTo,
    squarePriceFrom: clientFilter.squarePriceFrom,
    squarePriceTo: clientFilter.squarePriceTo,
    landAreaPriceFrom: clientFilter.landAreaPriceFrom,
    landAreaPriceTo: clientFilter.landAreaPriceTo,
    landAreaFrom: clientFilter.landAreaFrom,
    landAreaTo: clientFilter.landAreaTo,
    currency: clientFilter.currency,
    flooringFrom: clientFilter.flooringFrom,
    flooringTo: clientFilter.flooringTo,
    floorFrom: clientFilter.floorFrom,
    floorTo: clientFilter.floorTo,
    areaFrom: clientFilter.areaFrom,
    areaTo: clientFilter.areaTo,
    kitchenAreaFrom: clientFilter.kitchenAreaFrom,
    kitchenAreaTo: clientFilter.kitchenAreaTo,
    livingAreaFrom: clientFilter.livingAreaFrom,
    livingAreaTo: clientFilter.livingAreaTo,
    isMortgage: clientFilter.isMortgage,
  };

  Object.keys(filterAsQueryParams).forEach((key) => {
    if (filterAsQueryParams[key] === undefined || filterAsQueryParams[key] === null){
      delete filterAsQueryParams[key];
    }
  });

  return filterAsQueryParams;
};

export const getUrlWithClientFilterQueryParams = (clientFilter) => {
  const filters = buildClientSearchUrlParams(clientFilter);
  const queryParams = '?';

  return `${queryParams}${qs.stringify(filters)}`;
};

export const getClientFilterParamsFromQuery = (query) => {
  const params = qs.parse(query.replace('?', ''));

  for (const key in params) {
    const current = params[key];

    if (typeof current === 'string' ) {
      params[key] = Number.parseInt(current, 10);
    }

    if (Array.isArray(current)) {
      params[key] = new Set(current.map((val) => Number.parseInt(val, 10)));
    }
  }

  return params;
};
