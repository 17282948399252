/* eslint-disable max-lines */
import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

import Pagination from '../../layears/Pagination/Pagination';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import ObjectsControlPanel from '../../layears/ObjectsControlPanel/ObjectsControlPanel';
import { get, isEmpty } from 'lodash';
import NoObjectsFound from 'realtor/components/common/ObjectListContent/layouts/NoObjectsFound/NoObjectsFound';
import ObjectsPageHeader
  from 'realtor/components/pages/RealEstateObjectPages/MyObjectsPage/layouts/ObjectsPageHeader/ObjectsPageHeader';
import ObjectsList from 'realtor/components/layears/ObjectsList/ObjectsList';
import ObjectFiltersBar from 'realtor/components/layears/ObjectFiltersBar/ObjectFiltersBar';
import DeleteRealEstateObjectDialog
  from 'realtor/components/dialogs/DeleteRealEstateObjectDialog/DeleteRealEstateObjectDialog';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import i18n from '../../../../../common/i18n';
import DeleteRealEstateFromFavoriteDialog
  from 'realtor/components/dialogs/DeleteRealEstateFromFavoriteDialog/DeleteRealEstateFromFavoriteDialog';
// eslint-disable-next-line import/max-dependencies
import { useHistory, useLocation } from 'react-router-dom';
import {
  getClientFilterParamsFromQuery,
  // eslint-disable-next-line import/max-dependencies
} from 'realtor/components/layears/ClipClientFilterObjectsCount/helpers/searchUrl';

const DEFAULT_LIMITS = [10, 30];
const DEFAULT_OFFSET = 0;

const DEFAULT_PAGINATION = {
  limit: DEFAULT_LIMITS[0],
  offset: DEFAULT_OFFSET,
};

const DEFAULT_FILTERS = {
  search: '',
  showOnlyFavorites: false,
};


// eslint-disable-next-line max-statements
const ObjectList = (
  {
    orderKeys,
    onDelete,
    isOwnerList,
    fetchObjects,
    removeRealEstateFromFavorite,
    classes,
    pageTitle,
    isCreateButtonDisabled,
    objectsWithoutFiltersTotalCount,
    objectList,
    listInProgress,
    totalCount,
    tagsList,
    fetchTagsList,
    paramsList,
    orderList,
    inProgress,
    addRealEstateToFavorite,
    citiesList,
    exchangeRates,
  }
) => {
  const history = useHistory();
  const { search } = useLocation();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filterBarState, setFilterBarState] = useState(DEFAULT_FILTERS);
  const [listFetchParams, setListFetchParams] = useState({
    pagination: DEFAULT_PAGINATION,
    filters: DEFAULT_FILTERS,
    paramsFilter: {},
    order: 'FROM_NEWEST_TO_OLDER',
  });

  useEffect(() => {
    if (!isOwnerList && search) {
      const params = getClientFilterParamsFromQuery(search);
      setFilterBarState(params);

      if (search) {
        history.replace({
          pathname: window.location.pathname,
          state: history.location.state,
        });
      }

      setListFetchParams({
        ...listFetchParams,
        paramsFilter: params,
      });

      handleFilterChange(params);

      fetchObjects(getFetchParams({
        ...listFetchParams,
        paramsFilter: params,
      }));
    }
  }, [search]);

  useEffect(() => {
    if (!search) {
      fetchObjects(getFetchParams(listFetchParams));
    }
  }, [listFetchParams]);

  const [realEstateObjectDeleteDialog, setRealEstateObjectDeleteDialog] = useState({
    realEstateObjectToDelete: null,
    isDeleteDialogOpen: false,
  });

  const [realEstateObjectDeleteFromFavouriteDialog, setRealEstateObjectDeleteFromFavouriteDialog] = useState({
    realEstateObjectDeleteFromFavourite: null,
    isDeleteFromFavouriteDialogOpen: false,
  });

  const getFetchParams = (params) => {
    const tagId = params.filters.tag ? params.filters.tag.id : undefined;
    const { tag, ...filters } = params.filters;

    return {
      ...params.pagination,
      ...filters,
      ...params.paramsFilter,
      ...orderList[params.order],
      tagId,
    };
  };

  const handleFiltersToggle = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const handleDeleteObjectCancel = () => {
    setRealEstateObjectDeleteDialog({
      realEstateObjectToDelete: null,
      isDeleteDialogOpen: false,
    });
  };

  const handleDeleteObjectConfirm = () => {
    const data = {
      realEstateObjectToDelete: null,
      isDeleteDialogOpen: false,
    };

    onDelete(realEstateObjectDeleteDialog.realEstateObjectToDelete, getFetchParams(listFetchParams));
    setRealEstateObjectDeleteDialog(data);
  };

  const handleLimitChange = (limit) => {
    const pagination = {
      limit,
      offset: 0,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination,
    });
  };

  const handlePaginationBack = () => {
    const { pagination: { offset, limit } } = listFetchParams;

    const pagination = {
      ...listFetchParams.pagination,
      offset: offset - limit,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination,
    });
  };

  const handlePaginationForward = () => {
    const { pagination: { offset, limit } } = listFetchParams;
    const pagination = {
      ...listFetchParams.pagination,
      offset: offset + limit,
    };

    setListFetchParams({
      ...listFetchParams,
      pagination,
    });
  };

  const handleSearchChange = (params) => {
    setListFetchParams(
      {
        ...listFetchParams,
        pagination: {
          ...listFetchParams.pagination,
          offset: DEFAULT_OFFSET,
        },
        filters: {
          ...listFetchParams.filters,
          search: params.search,
          tag: get(params, 'tag', null),
        },
      }
    );
  };

  const handleFilterChange = (filters) => {
    setIsFiltersOpen(false);
    setListFetchParams({
      ...listFetchParams,
      paramsFilter: filters,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
    }
    );
  };

  const handleFilterReset = () => {
    setIsFiltersOpen(false);
    setListFetchParams({
      ...listFetchParams,
      paramsFilter: {},
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
    });
  };

  const handleOrderChange = (order) => {
    setIsFiltersOpen(false);
    setListFetchParams({
      ...listFetchParams,
      order,
    });
  };

  const handleShowOnlyFavoritesChange = () => {
    const showOnlyFavorites = !listFetchParams.filters.showOnlyFavorites;

    const filters = {
      ...listFetchParams.filters,
      showOnlyFavorites,
    };
    setIsFiltersOpen(false);
    setListFetchParams({
      ...listFetchParams,
      pagination: {
        ...listFetchParams.pagination,
        offset: DEFAULT_OFFSET,
      },
      filters,
    });

  };

  const handleDeleteObject = (realEstateObject) => {
    setRealEstateObjectDeleteDialog({
      realEstateObjectToDelete: realEstateObject,
      isDeleteDialogOpen: true,
    });
  };

  const handleDeleteRealEstateFromFavoriteCancel = () => {
    setRealEstateObjectDeleteFromFavouriteDialog({
      realEstateObjectDeleteFromFavourite: null,
      isDeleteFromFavouriteDialogOpen: false,
    });
  };

  const handleDeleteRealEstateFromFavoriteConfirm = () => {
    removeRealEstateFromFavorite(realEstateObjectDeleteFromFavouriteDialog.realEstateObjectDeleteFromFavourite);

    setRealEstateObjectDeleteFromFavouriteDialog({
      realEstateObjectDeleteFromFavourite: null,
      isDeleteFromFavouriteDialogOpen: false,
    });
  };

  const handleDeleteRealEstateFromFavoriteOpen = (realEstateObject) => {
    setRealEstateObjectDeleteFromFavouriteDialog({
      realEstateObjectDeleteFromFavourite: realEstateObject,
      isDeleteFromFavouriteDialogOpen: true,
    });
  };

  const renderHeader = () => {
    return (<ObjectsPageHeader
      classes={classes}
      totalCount={objectsWithoutFiltersTotalCount}
      title={pageTitle}
      isCreateButtonDisabled={isCreateButtonDisabled}
    />);
  };

  const renderNoObjectsFound = () => {
    return <NoObjectsFound classes={classes}/>;
  };

  const renderObjectsContent = () => {
    if (listInProgress) {
      return renderProgress();
    }

    const content = objectList.length ? renderObjectsList() : renderNoObjectsFound();

    return (
      <Fragment>
        {renderTopControls()}
        {content}
      </Fragment>
    );
  };

  const renderTopControls = () => {
    const isObjectListNotEmpty = Boolean(objectList.length);

    return (
      <Grid className={classes.controlPanelBox} container>
        <Grid className={classes.controlPanelBox} style={{ padding: '10px 0px' }} item container md={6} xs={12}>
          {!isOwnerList && renderOnlyFavoriteSwitcher()}
        </Grid>
        <Grid item md={6} xs={12}>
          {isObjectListNotEmpty && renderPagination()}
        </Grid>
      </Grid>
    );
  };

  const renderOnlyFavoriteSwitcher = () => {
    return (<CustomSwitch
      checked={listFetchParams.filters.showOnlyFavorites}
      label={i18n.t('ONLY_FAVORITES')}
      onChange={handleShowOnlyFavoritesChange}
      name={'showOnlyFavorites'}
      asButton={true}
    />);
  };

  const renderObjectsList = () => {
    return (
      <Fragment>
        <ObjectsList
          addRealEstateToFavorite={addRealEstateToFavorite}
          removeRealEstateFromFavorite={handleDeleteRealEstateFromFavoriteOpen}
          objects={objectList}
          paramsList={paramsList}
          isOwnerList={isOwnerList}
          onSelectTag={handleSearchChange}
          onDelete={handleDeleteObject}
        />
        {renderPagination(true)}
      </Fragment>
    );
  };

  const renderPagination = (isBottom = false) => {
    return (
      <Pagination
        isBottom={isBottom}
        limitsList={DEFAULT_LIMITS}
        onLimitChange={handleLimitChange}
        limit={listFetchParams.pagination.limit}
        offset={listFetchParams.pagination.offset}
        total={totalCount}
        onPaginationBack={handlePaginationBack}
        onPaginationForward={handlePaginationForward}
      />
    );
  };

  const renderProgress = () => {
    return <CircularProgressBox/>;
  };

  const renderContent = () => {
    const { paramsFilter, order, filters: { showClientsWithoutParams } } = listFetchParams;
    const { isDeleteDialogOpen, realEstateObjectToDelete } = realEstateObjectDeleteDialog;
    const {
      realEstateObjectDeleteFromFavourite,
      isDeleteFromFavouriteDialogOpen,
    } = realEstateObjectDeleteFromFavouriteDialog;

    const contentStyle = isFiltersOpen ? classes.contentBoxWithOpenedFilter : classes.contentBox;
    const isFiltersButtonActive = !isEmpty(paramsFilter);

    return (
      <Fragment>
        <div className={contentStyle}>
          {renderHeader()}
          <Paper className={classes.paper} elevation={0}>
            <ObjectsControlPanel
              onSearchChange={handleSearchChange}
              onFiltersToggle={handleFiltersToggle}
              isFiltersButtonActive={isFiltersButtonActive}
              orderList={orderKeys}
              currentOrder={order}
              onOrderChange={handleOrderChange}
              isFiltersButtonDisabled={showClientsWithoutParams}
              selectedTag={listFetchParams.filters.tag}
              fetchTagsList={fetchTagsList}
              tagsList={tagsList}
            />
            {renderObjectsContent()}
          </Paper>
        </div>
        <DeleteRealEstateObjectDialog
          isDeleteDialogOpen={isDeleteDialogOpen}
          onDialogClose={handleDeleteObjectCancel}
          realEstateObject={realEstateObjectToDelete}
          onDeleteConfirm={handleDeleteObjectConfirm}
        />
        <DeleteRealEstateFromFavoriteDialog
          isDeleteDialogOpen={isDeleteFromFavouriteDialogOpen}
          realEstateObject={realEstateObjectDeleteFromFavourite}
          onDialogClose={handleDeleteRealEstateFromFavoriteCancel}
          onDeleteConfirm={handleDeleteRealEstateFromFavoriteConfirm}
        />
        <ObjectFiltersBar
          filter={filterBarState}
          setFilters={setFilterBarState}
          paramsList={paramsList}
          exchangeRates={exchangeRates}
          onFiltersToggle={handleFiltersToggle}
          isFiltersOpen={isFiltersOpen}
          filtersList={paramsList}
          onConfirm={handleFilterChange}
          onReset={handleFilterReset}
          citiesList={citiesList}
        />
      </Fragment>
    );
  };

  return inProgress ? renderProgress() : renderContent();
};

ObjectList.propTypes = {
  classes: PropTypes.object,
  objectList: PropTypes.array,
  citiesList: PropTypes.array,
  fetchObjects: PropTypes.func,
  fetchTotalAndList: PropTypes.func,
  onDelete: PropTypes.func,
  addRealEstateToFavorite: PropTypes.func,
  removeRealEstateFromFavorite: PropTypes.func,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  paramsList: PropTypes.object,
  exchangeRates: PropTypes.array,
  pageTitle: PropTypes.string,
  isCreateButtonDisabled: PropTypes.bool,
  orderKeys: PropTypes.array.isRequired,
  orderList: PropTypes.object.isRequired,
  isOwnerList: PropTypes.bool,
  tagsList: PropTypes.array,
  fetchTagsList: PropTypes.string.isRequired,
  objectsWithoutFiltersTotalCount: PropTypes.number.isRequired,
};

export default ObjectList;
