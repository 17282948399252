import ClientFilterResource from '../resourses/ClientFilter.resource';
import { filterDomainFactory, serverFilterToDomainFactory } from '../domain';
import { CreateClientFilterDataBuilder } from 'realtor/helpers/requestDataBuildes/CreateClientFilterDataBuilder';
import Alert from 'react-s-alert';
import i18n from '../../../common/i18n';
import { onRedirect } from '../../actions/redirect';

export const FETCH_CLIENT_FILTER_START = 'FETCH_FILTER_START';
export const FETCH_CLIENT_FILTER_FAIL = 'FETCH_FILTER_FAIL';
export const FETCH_CLIENT_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS';

export const UPDATE_CLIENT_FILTER_START = 'UPDATE_FILTER_START';
export const UPDATE_CLIENT_FILTER_FAIL = 'UPDATE_FILTER_FAIL';
export const UPDATE_CLIENT_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';

export const CREATE_CLIENT_FILTER_START = 'CREATE_FILTER_START';
export const CREATE_CLIENT_FILTER_FAIL = 'CREATE_FILTER_FAIL';
export const CREATE_CLIENT_FILTER_SUCCESS = 'CREATE_FILTER_SUCCESS';

export const DELETE_CLIENT_FILTER_START = 'DELETE_FILTER_START';
export const DELETE_CLIENT_FILTER_FAIL = 'DELETE_FILTER_FAIL';
export const DELETE_CLIENT_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';


const updateClientFilterStart = () => {
  return { type: UPDATE_CLIENT_FILTER_START };
};

const updateClientFilterError = (error) => {
  return { type: UPDATE_CLIENT_FILTER_FAIL, error };
};

const updateClientSuccess = (filterData) => {
  return { type: UPDATE_CLIENT_FILTER_SUCCESS, filterData };
};

const fetchClientFilterStart = () => {
  return { type: FETCH_CLIENT_FILTER_START };
};

const fetchClientFilterError = (error) => {
  return { type: FETCH_CLIENT_FILTER_FAIL, error };
};

const fetchClientFilterSuccess = (clientFilter, types) => {
  return { type: FETCH_CLIENT_FILTER_SUCCESS, clientFilterData: serverFilterToDomainFactory(clientFilter, types) };
};

const createClientFilterStart = () => {
  return { type: CREATE_CLIENT_FILTER_START };
};

const createClientFilterError = (error) => {
  return { type: CREATE_CLIENT_FILTER_FAIL, error };
};

const createClientFilterSuccess = () => {
  return { type: CREATE_CLIENT_FILTER_SUCCESS };
};

export const deleteClientFilterStart = () => {
  return { type: DELETE_CLIENT_FILTER_START };
};

export const deleteClientFilterError = (error) => {
  return { type: DELETE_CLIENT_FILTER_FAIL, error };
};

export const deleteClientFilterSuccess = () => {
  return { type: DELETE_CLIENT_FILTER_SUCCESS };
};

export const fetchClientFilter = (clientId, filterId) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(fetchClientFilterStart());
    ClientFilterResource.getSingleClientFilter(clientId, filterId)
      .then((clientFilterData) => {
        dispatch(fetchClientFilterSuccess(
          clientFilterData,
          state.appConfigurations.objectFilters.objectType
        ));
      })
      .catch((error) => {
        dispatch(fetchClientFilterError(error));
      });
  };
};

export const createClientFilter = (clientId, clientFilterData) => {
  return (dispatch, getState) => {
    const state = getState();

    const filterDomain = filterDomainFactory(
      clientFilterData,
      state.appConfigurations.objectFilters.objectType
    );

    dispatch(createClientFilterStart());
    const requestData = CreateClientFilterDataBuilder.build({
      filterDomain,
    });

    ClientFilterResource.createSingleClientFilter(clientId, requestData)
      .then(() => {
        dispatch(createClientFilterSuccess());
        Alert.success(
          i18n.t('CLIENT_SUCCESSFULLY_CREATED'),
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
        return dispatch(onRedirect('/clients'));
      })
      .catch((error) => {
        dispatch(createClientFilterError(error));
      });
  };
};

export const updateClientFilter = (clientId, filterId, clientFilterData) => {
  return (dispatch, getState) => {
    const state = getState();

    const filterDomain = filterDomainFactory(
      clientFilterData,
      state.appConfigurations.objectFilters.objectType
    );

    dispatch(updateClientFilterStart());
    const requestData = CreateClientFilterDataBuilder.build({
      filterDomain,
    });

    ClientFilterResource.updateSingleClientFilter(clientId, filterId, requestData)
      .then((clientFilterData) => {
        dispatch(updateClientSuccess(clientFilterData));
        Alert.success(
          i18n.t('CLIENT_FILTER_SUCCESSFULLY_UPDATED'),
          { position: 'top-right', effect: 'slide', timeout: 3000 }
        );
        return dispatch(onRedirect('/clients'));
      })
      .catch((error) => {
        dispatch(updateClientFilterError(error));
      });
  };
};

export const deleteClientFilter = (clientId, filterId) => {
  return (dispatch) => {
    dispatch(deleteClientFilterStart());
    ClientFilterResource.deleteSingleClientFilter(clientId, filterId)
      .then(() => {
        dispatch(deleteClientFilterSuccess());
      })
      .catch((error) => {
        dispatch(deleteClientFilterError(error));
      });
  };
};

export const dropHasNewObjects = (filterId, clientId) => {
  return () => {
    ClientFilterResource.dropHasNewObjects(filterId, clientId);
  };
};
