import { connect } from 'react-redux';

import
UpdateClientFilterPage
  from '../../components/pages/ClientFilterPages/UpdateClientFilterPage/UpdateClientFilterPage';

import { fetchClientFilter, fetchTagsList, updateClientFilter } from '../../actions';

const mapStateToProps = (state, { match }) => {
  const { clientFilter, appConfigurations } = state;

  return {
    clientFilter: clientFilter.clientFilter,
    inProgress: clientFilter.inProgress,
    updateInProgress: clientFilter.updateInProgress,
    errors: clientFilter.error,
    clientId: Number(match.params.clientId),
    filterId: Number(match.params.filterId),
    appConfigurations,
  };
};

const mapDispatchToProps = {
  fetchClientFilter,
  updateClientFilter,
  fetchTagsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClientFilterPage);
