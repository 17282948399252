import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes, { bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, } from 'react-router-dom';
import 'react-s-alert/dist/s-alert-default.css';
/* @ts-ignore */
import styles from './styles';
/* @ts-ignore */
import AllAccessRoutes from '../../allAccess/routes/AllAccessRoutes';
/* @ts-ignore */
import { RealtorCabinetContainer } from 'realtor/containers';
export const App = ({ isConfirmationTokenExist, isAppInitialized, redirectPath, classes, isAuthorized, }) => {
    const renderNotAuthorizedRoutes = () => (_jsx(AllAccessRoutes, { isConfirmationTokenExist: isConfirmationTokenExist }));
    const renderAuthorizedRoutes = () => (_jsx(RealtorCabinetContainer, { isAppInitialized: isAppInitialized, redirectPath: redirectPath }));
    return (_jsx("div", { className: classes.appWrapper, children: _jsx("main", { className: classes.mainContent, children: _jsx(Router, { children: isAuthorized ? renderAuthorizedRoutes() : renderNotAuthorizedRoutes() }) }) }));
};
export default withStyles(styles)(App);
App.propTypes = {
    isAuthorized: bool,
    isConfirmationTokenExist: bool,
    isAppInitialized: bool,
    classes: PropTypes.object.isRequired,
    redirectPath: PropTypes.string,
};
