import { BaseValidator } from './BaseValidator';

export class HouseFilterValidator extends BaseValidator{
  // eslint-disable-next-line max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.flooring = this.validateFlooring();

    errors.kitchenArea = this.validateKitchenArea();

    errors.squarePrice = this.validateSquarePrice();

    errors.totalPrice = this.validateTotalPrice();

    errors.livingArea = this.validateLivingArea();

    return errors;
  }
}
