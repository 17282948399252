import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Alert from 'react-s-alert';

import styles from './styles';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import i18n from '../../../../../common/i18n';
import CreateFilterForm from '../CreateFilterForm/CreateFilterForm';
import { DEFAULT_FILTER } from '../../pages/ClientsPages/CreateClientPage/CreateClientPage';
import { clientFilterFormValidationFactory } from 'realtor/validation/clientFilter/clientFilterFormValidationFactory';
import { Link as RouterLink } from 'react-router-dom';
import { getDefaultFilterByType } from 'realtor/helpers/clientFilterParams';

// eslint-disable-next-line max-statements
export const CreateSingleFilterForm = ({
  filter,
  appConfigurations,
  onConfirm,
  classes,
  confirmButtonText,
  cancelButtonText,
  disableTypeSelect,
  inProgress,
}) => {
  const [filterLocal, setFilterLocal] = useState(filter);
  const [filterErrors, setFilterErrors] = useState({});

  useEffect(() => {
    setFilterLocal(filter);
  }, [filter]);

  const validateFilterForm = () => {
    const validator = clientFilterFormValidationFactory(
      appConfigurations.objectFilters.objectType,
      appConfigurations.exchangeRates,
      filterLocal
    );

    const errors = validator.validate();

    return {
      isFilterFormValid: FormValidation.isFormValid(errors),
      filterErrors: errors,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterErrors({});

    const { isFilterFormValid, filterErrors } = validateFilterForm();

    setFilterErrors(filterErrors);

    if (isFilterFormValid) {
      onConfirm(filterLocal);
    } else {
      Alert.error(
        i18n.t('CREATE_CLIENT_FORM_ERROR'),
        { position: 'top-right', effect: 'slide', timeout: 3000 }
      );
    }

    return false;
  };

  const handleFilterMultiSelect = (name, value, isAllSelected) => {
    let param = filterLocal[name];

    if (!param || isAllSelected) {
      param = new Set();
    }

    param.has(value) ? param.delete(value) : param.add(value);

    return setFilterLocal({
      ...filterLocal,
      [name]: param,
    });
  };

  const handleFilterMultiSelectAll = (name, params) => {
    return setFilterLocal({
      ...filterLocal,
      [name]: params,
    });
  };

  const handleFilterRealEstateSelect = (name, value) => {
    return setFilterLocal({
      ...filterLocal,
      [name]: value,
    });
  };

  const handleServiceRealEstateSelect = (name, value) => {
    return setFilterLocal({
      ...filterLocal,
      isMortgage: null,
      [name]: value,
    });
  };

  const handleFilterRealEstateTypeSelect = (name, value) => {
    setFilterErrors({});
    return setFilterLocal({
      ...getDefaultFilterByType(value, appConfigurations.objectFilters),
      ...DEFAULT_FILTER,
      [name]: value,
    });
  };

  const handleCityChange = (event) => {
    return setFilterLocal({
      ...filterLocal,
      cityId: get(event, 'cityId', null),
      districtId: null,
    });
  };

  const handleDistrictChange = (data) => {
    return setFilterLocal({
      ...filterLocal,
      districtId: data ? data.id : null,
    });
  };

  const handleSaveWithoutFilter = () => {
    Alert.success(
      i18n.t('CLIENT_SUCCESSFULLY_CREATED'),
      { position: 'top-right', effect: 'slide', timeout: 3000 }
    );
  };

  const buttonTextConfirm = confirmButtonText || i18n.t('ADD_CLIENT');
  const buttonTextCancel = cancelButtonText || i18n.t('CANCEL');
  const isSaveButtonDisabled = !filterLocal.objectType || inProgress;

  return (
    <form
      onSubmit={handleSubmit}
    >
      <div>
        <CreateFilterForm
          filtersList={appConfigurations.objectFilters}
          filter={filterLocal}
          citiesList={appConfigurations.citiesList}
          onMultiSelect={handleFilterMultiSelect}
          onFilterRealEstateTypeSelect={handleFilterRealEstateTypeSelect}
          onFilterRealEstateSelect={handleFilterRealEstateSelect}
          onFilterServiceSelect={handleServiceRealEstateSelect}
          onCityChange={handleCityChange}
          onDistrictChange={handleDistrictChange}
          onMultiSelectAll={handleFilterMultiSelectAll}
          errors={filterErrors}
          disableTypeSelect={disableTypeSelect}
        />
      </div>
      <Grid container spacing={2} className={classes.controlButtons}>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSaveButtonDisabled}
          >
            {buttonTextConfirm}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="default"
            className={classes.cancel}
            component={RouterLink} to={'/clients'}
            onClick={handleSaveWithoutFilter}
          >
            {buttonTextCancel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default withStyles(styles)(CreateSingleFilterForm);

CreateSingleFilterForm.propTypes = {
  filter: PropTypes.object,
  classes: PropTypes.object,
  onConfirm: PropTypes.func,
  appConfigurations: PropTypes.object,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableTypeSelect: PropTypes.bool,
  inProgress: PropTypes.bool,
};
