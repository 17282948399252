import { connect } from 'react-redux';
import {
  addSingleObjectToFavourite,
  getRealEstateObjectDetails,
  removeSingleObjectToFavourite,
} from 'realtor/actions';
import RealEstateObjectDetailsPage
  from 'realtor/components/pages/RealEstateObjectPages/RealEstateObjectDetailsPage/RealEstateObjectDetailsPage';

const mapStateToProps = ({ appConfigurations, realEstateObject }, { match }) => {
  return {
    appConfigurations,
    inProgress: realEstateObject.inProgress,
    objectMutationInProgress: realEstateObject.objectMutationInProgress,
    realEstateObject: realEstateObject.realEstateObject,
    realEstateObjectId: Number(match.params.realEstateObjectId),
    filters: appConfigurations.objectFilters,
  };
};

const mapDispatchToProps = {
  fetchObject: getRealEstateObjectDetails,
  addRealEstateToFavorite: addSingleObjectToFavourite,
  removeRealEstateFromFavorite: removeSingleObjectToFavourite,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateObjectDetailsPage);
