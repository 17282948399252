import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import i18n from '../../../../i18n';
import Copyright from '../../../../components/Copyright/Copyright';

const AuthFooterLayer = ({ classes, confirmText }) => {
  return (
    <div className={classes.footer}>
      <Typography className={classes.formFooterDescription} align={'center'}>
        {confirmText}
        <br/>
        <Link to="/404" component={RouterLink}>
          {i18n.t('TERMS_AND_CONDITIONS')}
        </Link>
      </Typography>
      <Copyright/>
    </div>
  );
};

export default withStyles(styles)(AuthFooterLayer);

AuthFooterLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  confirmText: PropTypes.string,
};
