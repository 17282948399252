import {
  CREATE_CLIENT_FAIL,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_START,
  FETCH_CLIENT_START,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_FAIL,
  UPDATE_CLIENT_START,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  DROP_ERRORS_SUCCESS,
  RESTORE_CLIENT_FAIL,
  RESTORE_CLIENT_START,
  RESTORE_CLIENT_SUCCESS, DELETE_CLIENT_START, DELETE_CLIENT_SUCCESS, DELETE_CLIENT_FAIL,
} from '../actions/';

const initialState = {
  inProgress: false,
  error: null,
  client: null,
};

// eslint-disable-next-line complexity
export const client = (state = initialState, action) => {
  switch (action.type) {
  case CREATE_CLIENT_START:
  case UPDATE_CLIENT_START:
  case RESTORE_CLIENT_START:
  case DELETE_CLIENT_START:
    return {
      ...state,
      error: null,
      inProgress: true,
    };
  case CREATE_CLIENT_FAIL:
  case UPDATE_CLIENT_FAIL:
  case RESTORE_CLIENT_FAIL:
  case DELETE_CLIENT_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };

  case CREATE_CLIENT_SUCCESS:
  case UPDATE_CLIENT_SUCCESS:
  case DELETE_CLIENT_SUCCESS:
    return {
      ...state,
      error: null,
      inProgress: false,
      client: null,
    };
  case FETCH_CLIENT_START:
    return {
      ...state,
      error: null,
      inProgress: true,
      client: null,
    };
  case FETCH_CLIENT_FAIL:
    return {
      ...state,
      error: action.error,
      inProgress: false,
    };
  case FETCH_CLIENT_SUCCESS:
    return {
      ...state,
      error: null,
      inProgress: false,
      client: action.clientData,
    };
  case DROP_ERRORS_SUCCESS:
    return {
      ...state,
      error: null,
    };
  case RESTORE_CLIENT_SUCCESS:
    return {
      ...state,
      inProgress: false,
    };
  default:
    return state;
  }
};
