import ObjectResource from '../resourses/Object.resource';
import { Client, TelephoneNumberBuilder } from 'realtor/domain';
import {
  fetchRealEstateObjectTotalCountFail,
  fetchRealEstateObjectTotalCountStart,
  fetchRealEstateObjectTotalCountSuccess,
} from 'realtor/actions/myObjectsList';

export const FETCH_OBJECTS_START = 'FETCH_OBJECTS_START';
export const FETCH_OBJECTS_FAIL = 'FETCH_OBJECTS_FAIL';
export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS';

export const FETCH_OBJECTS_TOTAL_START = 'FETCH_OBJECTS_TOTAL_START';
export const FETCH_OBJECTS_TOTAL_FAIL = 'FETCH_OBJECTS_TOTAL_FAIL';
export const FETCH_OBJECTS_TOTAL_SUCCESS = 'FETCH_OBJECTS_TOTAL_SUCCESS';

const fetchTotalStart = () => {
  return { type: FETCH_OBJECTS_TOTAL_START };
};

const fetchTotalFail = (error) => {
  return { type: FETCH_OBJECTS_TOTAL_FAIL, error };
};

const fetchTotalSuccess = (data) => {
  return { type: FETCH_OBJECTS_TOTAL_SUCCESS, data };
};

const fetchObjectsStart = () => {
  return { type: FETCH_OBJECTS_START };
};

const fetchObjectsFail = (error) => {
  return { type: FETCH_OBJECTS_FAIL, error };
};

const fetchObjectsSuccess = (data) => {
  return { type: FETCH_OBJECTS_SUCCESS, data: {
    ...data,
    result: data.result.map((realEstateObject) => {

      const client = realEstateObject.client ?
        new Client({
          ...realEstateObject.client,
          telephoneNumber: TelephoneNumberBuilder.buildFromServerData(realEstateObject.client.telephoneNumber),
        }) :
        null;
      return {
        ...realEstateObject,
        client,
      };
    }),
  },
  };
};

export const fetchObjects = (params) => {
  return (dispatch) => {
    dispatch(fetchObjectsStart());
    ObjectResource.getSearchObjectsList(params)
      .then((response) => {
        return dispatch(fetchObjectsSuccess(response));
      })
      .catch((error) => {
        return dispatch(fetchObjectsFail(error));
      });
  };
};

export const fetchObjectsTotal = (params) => {
  return (dispatch, getState) => {
    dispatch(fetchTotalStart());
    dispatch(fetchObjectsStart());
    ObjectResource.getObjectsTotal()
      .then((data) => {
        dispatch(fetchTotalSuccess(data));
        if (data.total) fetchObjects(params)(dispatch, getState);
      })
      .catch((error) => {
        return dispatch(fetchTotalFail(error));
      });
  };
};

export const getRealEstateObjectTotalCount = () => {
  return (dispatch) => {
    dispatch(fetchRealEstateObjectTotalCountStart());
    ObjectResource.getObjectsTotal()
      .then((data) => {
        dispatch(fetchRealEstateObjectTotalCountSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchRealEstateObjectTotalCountFail(err));
      });

  };
};

export const dropRealEstateObjectsTotalCount = () => {
  return (dispatch) => {
    return dispatch(fetchRealEstateObjectTotalCountSuccess({ totalCount: null }));
  };
};
