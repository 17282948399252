import { BaseValidator } from './BaseValidator';

export class GarageParkingFilterValidator extends BaseValidator{
  validate() {
    const errors = {};

    errors.totalPrice = this.validateTotalPrice();

    return errors;
  }
}
