import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUrlWithClientFilterQueryParams } from './helpers/searchUrl';
import ClipWithCounter from 'realtor/components/layears/ClipWithCounter/ClipWithCounter';
import { dropHasNewObjects } from 'realtor/actions';

const ClipClientFilterObjectsCount = ({
  clientFilter,
  dropHasNewObjects,
  clientId,
}) => {
  const history = useHistory();

  const handleClick = (e) => {
    e.stopPropagation();
    dropHasNewObjects(clientFilter.id, clientId);
    const urlParams = getUrlWithClientFilterQueryParams(clientFilter);
    return history.push(`/search-objects${urlParams}`);
  };

  return (
    <ClipWithCounter
      count={clientFilter.realEstateObjectsCount}
      onClick={handleClick}
      isActive={clientFilter.hasNewRealEstateObjects}
    />
  );
};

ClipClientFilterObjectsCount.propTypes = {
  clientFilter: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  dropHasNewObjects: PropTypes.func,
};

const mapDispatchToProps = {
  dropHasNewObjects,
};

export const ClipClientFilterObjectsCountWidget = connect(null, mapDispatchToProps)(ClipClientFilterObjectsCount);
