export class BaseFilter {
  constructor(props) {
    this.props = props;
  }

  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get objectType() {
    return this.props.objectType;
  }

  set objectType(objectType) {
    this.props.objectType = objectType;
  }

  get cityId() {
    return this.props.cityId;
  }

  set cityId(cityId) {
    this.props.cityId = cityId;
  }

  get districtId() {
    return this.props.districtId;
  }

  set districtId(districtId) {
    this.props.districtId = districtId;
  }

  get description() {
    return this.props.description;
  }

  set description(description) {
    this.props.description = description;
  }

  get currency() {
    return this.props.currency;
  }

  set currency(currency) {
    this.props.currency = currency;
  }

  get totalPriceFrom() {
    return this.props.totalPriceFrom;
  }

  set totalPriceFrom(totalPriceFrom) {
    this.props.totalPriceFrom = totalPriceFrom;
  }

  get totalPriceTo() {
    return this.props.totalPriceTo;
  }

  set totalPriceTo(totalPriceTo) {
    this.props.totalPriceTo = totalPriceTo;
  }

  get objectServiceType() {
    return this.props.objectServiceType;
  }

  set objectServiceType(objectServiceType) {
    this.props.objectServiceType = objectServiceType;
  }

  get livingAreaFrom() {
    return this.props.livingAreaFrom;
  }

  set livingAreaFrom(livingAreaFrom) {
    this.props.livingAreaFrom = livingAreaFrom;
  }

  get livingAreaTo() {
    return this.props.livingAreaTo;
  }

  set livingAreaTo(livingAreaTo) {
    this.props.livingAreaTo = livingAreaTo;
  }

  get location() {
    return this.props.location;
  }
  set location(location) {
    this.props.location = location;
  }
  get code() {
    return this.props.code;
  }
  set code(code) {
    this.props.code = code;
  }

  get realEstateObjectsCount() {
    return this.props.realEstateObjectsCount;
  }
  set realEstateObjectsCount(realEstateObjectsCount) {
    this.props.realEstateObjectsCount = realEstateObjectsCount;
  }

  get hasNewRealEstateObjects() {
    return this.props.hasNewRealEstateObjects;
  }
  set hasNewRealEstateObjects(hasNewRealEstateObjects) {
    this.props.hasNewRealEstateObjects = hasNewRealEstateObjects;
  }

  toBaseServerEntity() {
    return {
      id: this.props.id,
      realEstateType: this.props.objectType,
      cityId: this.props.cityId,
      districtId: this.props.districtId,
      description: this.props.description,
      currency: this.props.currency,
      totalPriceFrom: this.props.totalPriceFrom ? Number(this.props.totalPriceFrom) : null,
      totalPriceTo: this.props.totalPriceTo ? Number(this.props.totalPriceTo) : null,
    };
  }

  toFormEntity() {
    return this.props;
  }
}
