import { connect } from 'react-redux';

import {
  fetchObjects,
  fetchTagsList,
  addRealEstateToFavorite,
  removeRealEstateFromFavorite,
  getRealEstateObjectTotalCount, dropRealEstateObjectsTotalCount,
} from 'realtor/actions';
import SearchObjectPage from 'realtor/components/pages/RealEstateObjectPages/SearchObjectPage/SearchObjectPage';

const mapStateToProps = (state) => {
  const { appConfigurations, objectsList, tag, realEstateObjectsTotalCount } = state;

  return {
    paramsList: appConfigurations.objectFilters,
    objectList: objectsList.list,
    totalListCount: objectsList.totalListCount,
    totalCount: objectsList.totalListCount,
    inProgress: objectsList.inProgress,
    listInProgress: objectsList.listInProgress,
    favoriteActionInProgress: objectsList.favoriteActionInProgress,
    citiesList: appConfigurations.citiesList,
    isOwnerList: false,
    tagsList: tag.tagsList,
    totalCountInProgress: realEstateObjectsTotalCount.inProgress,
    objectsWithoutFiltersTotalCount: realEstateObjectsTotalCount.totalCount,
    exchangeRates: appConfigurations.exchangeRates,
  };
};

const mapDispatchToProps = {
  fetchObjects,
  fetchTagsList,
  addRealEstateToFavorite,
  removeRealEstateFromFavorite,
  getRealEstateObjectTotalCount,
  dropRealEstateObjectsTotalCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchObjectPage);
