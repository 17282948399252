import React, { Fragment, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  TextField,
  FormHelperText,
  OutlinedInput,
  FormControl,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { FormValidation } from '../../../../../common/helpers/FormValidation';
import i18n from '../../../../i18n';
import styles from './styles';
import PasswordField from '../../fields/PasswordField/PasswordField';
import { FullScreenLoader } from '../../../../components/FullScreenLoader/FullScreenLoader';

const RegistrationForm = ({
  classes,
  odRegistration,
  onDropErrors,
  errors,
  inProgress,
}) => {
  const [localErrors, setLocalErrors] = useState({});
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    telephoneNumber: '',
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (!isEmpty(errors)){
      return setLocalErrors(errors);
    }
  }, [errors]);

  const passwordValidation = () => {
    const { password } = values;
    if (!password) {
      return i18n.t('FIELD_REQUIRED');
    }

    if (password.length < 8) {
      return i18n.t('INVALID_PASSWORD_LENGTH');
    }

    const re = /(?=.*\d)(?=.*[A-ZА-ЯҐЄІЇЎЁ]).{8,}/;
    if (!re.test(password)) {
      return i18n.t('INVALID_PASSWORD');
    }

    return null;
  };

  const passwordConfirmValidation = () => {
    const { password, confirmPassword } = values;
    if (!confirmPassword) {
      return i18n.t('FIELD_REQUIRED');
    }

    if (confirmPassword !== password) {
      return i18n.t('PASSWORDS_DO_NOT_MATCH');
    }

    return null;
  };

  const validateForm = () => {
    const errors = {};
    const { telephoneNumber } = values;

    errors.password = passwordValidation();
    errors.confirmPassword = passwordConfirmValidation();
    errors.telephoneNumber = FormValidation.validateRequired(telephoneNumber);
    if (telephoneNumber) {
      errors.telephoneNumber = FormValidation.validateTelephoneNumber(telephoneNumber);
    }
    setLocalErrors(errors);
    return FormValidation.isFormValid(errors);
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    return setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFromValid = validateForm();
    isFromValid ? odRegistration(values) : onDropErrors();

    return false;
  };

  return (
    <Fragment>
      {inProgress && <FullScreenLoader />}
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit}
        method={'POST'}
      >
        <FormControl fullWidth margin={'normal'}>
          <label htmlFor="outlined-adornment-telephoneNumber" >{i18n.t('TELEPHONE_NUMBER')}</label>
          <InputMask
            mask="+38(099) 999 99 99"
            onChange={handleFieldChange}
            value={values.telephoneNumber}
            alwaysShowMask
          >
            {() => (
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                name="telephoneNumber"
                type="test"
                id="telephoneNumber"
                helperText={localErrors.telephoneNumber}
                error={!!localErrors.telephoneNumber}
              />
            )}
          </InputMask>
        </FormControl>
        <PasswordField
          onFieldChange={handleFieldChange}
          error={localErrors.password}
          value={values.password}
          onIsPasswordVisibleChange={setIsPasswordVisible}
        />

        <FormControl fullWidth margin={'normal'} variant="outlined">
          <label htmlFor="outlined-adornment-confirmPassword">{i18n.t('CONFIRM_PASSWORD')}</label>
          <OutlinedInput
            variant="outlined"
            required
            name="confirmPassword"
            type={isPasswordVisible ? 'text' : 'password'}
            id="confirmPassword"
            onChange={handleFieldChange}
            value={values.confirmPassword}
            error={!!localErrors.confirmPassword}
          />
          {!!localErrors.confirmPassword && (
            <FormHelperText error>
              {localErrors.confirmPassword}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={inProgress}
        >
          {i18n.t('CONFIRM_REGISTRATION')}
        </Button>
      </form>
    </Fragment>
  );
};

export default withStyles(styles)(RegistrationForm);

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  odRegistration: PropTypes.func.isRequired,
  onDropErrors: PropTypes.func.isRequired,
  errors: PropTypes.object,
  inProgress: PropTypes.bool,
};
